import { useState } from "react";

import Link from "next/link";

import { useUser } from "../../lib/context/usercontext";

import AuthModal from "../authmodal";
import ScheduleCard from "../schedulecard";
import ScheduleTableRow from "../scheduletablerow";

function InviteToAnonymousLogin() {
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const { user } = useUser();

    if (user || !process.env.NEXT_PUBLIC_INVITE_TO_LOGIN_IN_GAME_LIST) {
        return null;
    }

    return (
        <>
            <div className="mt-3 mb-3 mt-md-2 mb-md-n3 text-center">
                Чтобы увидеть все игры{" "}
                <button className="btn btn-primary btn-sm px-3" onClick={() => setAuthModalVisible(true)}>
                    Войдите на сайт
                </button>
            </div>
            {authModalVisible && <AuthModal onHide={() => setAuthModalVisible(false)} />}
        </>
    );
}

export default function Schedule({
    games,
    currentCity,
    title,
    dateSelector,
    dateSelectorAboveTitle,
    showEmptyGameList,
    showPlacesLeftInfo,
    showEnrollmentLink,
    showLoginForAnonymous,
    showFormula,
    isArchive,
    isExpandableEnrollment,
    isExpandedEnrollment,
    isGamesList,
    className,
    onGameChange,
}) {
    if (games.length == 0 && !showEmptyGameList) {
        return null;
    }

    return (
        <>
            <div className="container-fluid">
                <div className={"schedule schedule--mobile " + (className || "")}>
                    {dateSelectorAboveTitle && dateSelector}
                    <h3 className="schedule__title">
                        <b>{title}</b>
                    </h3>
                    {!dateSelectorAboveTitle && dateSelector}
                    {showLoginForAnonymous && <InviteToAnonymousLogin />}
                    {games.map((game) => (
                        <ScheduleCard
                            game={game}
                            expandedByDefault
                            isArchive={isArchive}
                            isGamesList={isGamesList}
                            showPlacesLeftInfo={showPlacesLeftInfo}
                            onEnrollment={onGameChange}
                            showFormula={showFormula}
                            key={game.id}
                        />
                    ))}
                </div>

                <div className={"schedule schedule--desktop " + (className || "")}>
                    {dateSelectorAboveTitle && dateSelector}
                    <h3 className="schedule__title">
                        <b>{title}</b>
                    </h3>
                    {!dateSelectorAboveTitle && dateSelector}
                    {showLoginForAnonymous && <InviteToAnonymousLogin />}
                    <table className="schedule__table">
                        {!isExpandedEnrollment && (
                            <thead>
                                <tr className="schedule__table__tr">
                                    <th>Дата и время</th>
                                    <th>Ведущий</th>
                                    <th>Игра</th>
                                    <th>Локация</th>
                                    {!isArchive && <th>Стоимость</th>}
                                    <th>{isArchive ? "Участники" : "Бронирование мест"}</th>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {games.map((game) => (
                                <ScheduleTableRow
                                    game={game}
                                    isArchive={isArchive}
                                    isExpandableEnrollment={isExpandableEnrollment}
                                    isExpandedEnrollment={isExpandedEnrollment}
                                    showPlacesLeftInfo={showPlacesLeftInfo}
                                    key={`${game.id} ${isExpandedEnrollment}`}
                                    onEnrollment={onGameChange}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>

                {showEnrollmentLink && (
                    <div className="schedule__enrollment-link">
                        <Link href={`/${currentCity.slug}/enrollment/`}>
                            <a className="btn btn-primary btn-wide-padding">Полное расписание</a>
                        </Link>
                    </div>
                )}

                {isArchive && (
                    <div className="schedule__enrollment-link">
                        <Link href={`/${currentCity.slug}/archive/`}>
                            <a className="btn btn-primary btn-wide-padding">Архив игр</a>
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
}
